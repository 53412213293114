body {
  background-color: black;
  color: white;
  font-family: "Courier New", Courier, monospace;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-image: url("/public/images/bg-web.jpg");
  background-size: cover;
  background-position: center;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 90vh;
  padding: 20px;
  backdrop-filter: blur(5px);
}

.header h1 {
  font-size: 3rem;
  color: #f17ff9; /* Cool Purple */
  text-shadow: 0 0 10px #f17ff9;
}

.terminal-container {
  background-color: rgba(0, 0, 0, 0.7);
  border: 2px solid #f17ff9;
  width: 80%;
  max-width: 800px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 20px rgba(142, 68, 173, 0.7); /* Cool Purple */
}

.chat-container {
  flex-grow: 1;
  padding: 10px 20px;
  text-align: left;
  overflow-y: auto;
}

.fade-in {
  opacity: 0;
  animation: fade-in 0.5s ease-in forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.user {
  color: white;
}

.bot {
  color: #f17ff9; /* Cool Purple */
}

.prompt-input {
  height: 70px;
  width: calc(100% - 40px);
  padding: 10px;
  font-size: 16px;
  background-color: black;
  color: white;
  border: none;
  border-top: 2px solid #f17ff9; /* Cool Purple */
  box-sizing: border-box;
}

.loading,
.typing {
  font-style: italic;
  color: #f17ff9;
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.footer {
  margin-top: 20px;
}

.footer a {
  color: #f17ff9; /* Cool Purple */
  text-decoration: none;
  margin: 0 10px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer a:hover {
  color: #ffffff;
  text-shadow: 0 0 5px #f17ff9;
}

.clear-chat {
  font-size: 14px;
  color: #f17ff9;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
}
